import { HelmetFunc } from 'components/PageMetaData'

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { displayWidth } from 'styles/width'

import { useTranslation } from 'react-i18next'
import { sendEvent, gtag } from 'tracking'

import { DefaultFormBlock } from 'blocks/DefaultFormBlock'

import { Header } from 'blocks/Header/Header'
import { graphql } from 'gatsby'
import { usePagePath } from 'hooks/usePagePath'

import { Style, StyleDesktop } from 'blocks/Style'
import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'
import { StylesSliderBlock } from 'blocks/StylesSliderBlock'
import { LeftSidebar, RightSidebar } from 'components/SideBar'
import { backgroundColors, colors } from 'styles/colors'
import { Container } from 'components/Container'
import { indent } from 'styles/indent'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { TitleH1 } from 'components/TitleComponent'

const Desktop = styled.div`
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const Wrap = styled.div`
    display: block;
    top: 66px;
    height: calc(100vh - 126px);
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    overflow: auto;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        top: 80px;
        height: calc(100vh - 80px);
        left: 0;
        right: 0;
        bottom: 0px;
        position: absolute;
        overflow: auto;
    }
`

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`

const HeroColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
        border-right: 1px solid #231f20;
        justify-content: center;
        border-right: none;
        align-items: center;
    }
`

const TitleStyled = styled(TitleH1)`
    margin-top: 20px;
    max-width: 250px;
    text-align: center;
    font-size: 24px;
    @media (min-width: ${displayWidth.tablet}) {
        font-size: 40px;
        margin-left: ${indent.heroColumnTablet};
        max-width: 90%;
    }
    @media (min-width: ${displayWidth.desktop}) {
        margin-left: ${indent.heroColumnDesktop};
        max-width: 1000px;
    }
`

const pageMetadata = {
    uk: {
        title: 'Підбір поєднання кольорів і стилю в дизайні інтер`єру',
        description:
            'Етапи підбору стилю, форм, кольорів і текстур в дизайні інтер`єру квартир, будинків і комерційних приміщень від дизайнера-декоратора студії "Clearline"',
    },
    ru: {
        title: 'Подбор сочетания цветов и стиля в дизайне интерьера',
        description:
            'Этапы подбора стиля, форм, цветов и текстур в дизайне интерьера для квартир, домов и коммерческих помещений от дизайнера-декоратора студии “Clearline”',
    },
    en: {
        title:
            'Selecting the combination of colors and style in interior design',
        description:
            'Stages of selecting style, shapes, colors and textures in interior design for apartments, houses and commercial premises from the designer-decorator of the studio "Clearline"',
    },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StylePage = ({ data }: { data: any }) => {
    const { i18n, t } = useTranslation()
    const { getPagePath } = usePagePath()
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])

    const onScroll = () => {
        const block = document.getElementById('blockF')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight
        const blockMod = document.getElementById('wrap')
        const scrollPositionMob = blockMod!.scrollTop
        const windowHeightMob = blockMod!.clientHeight
        const bodyHeightMob = blockMod!.scrollHeight
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            const trackScrollMob = () => {
                const scrolledRationMob = Math.ceil(
                    ((scrollPositionMob + windowHeightMob) / bodyHeightMob) *
                        100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRationMob >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled75Send!.current &&
                    scrolledRationMob >= 75
                ) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled50Send!.current &&
                    scrolledRationMob >= 50
                ) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled25Send!.current &&
                    scrolledRationMob >= 25
                ) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScrollMob()
            trackScroll()
        }, 700)
    }

    return (
        <>
            <HelmetFunc data={pageMetadata} />
            <Wrap id="wrap" onScroll={onScroll}>
                <MobileMenuPosadka />
                <PosadkaLinkBlock />
                <TitleWrapper>
                    <LeftSidebar background={backgroundColors.contact} />
                    <Container columns={'1fr'}>
                        <HeroColumn>
                            <TitleStyled>
                                {t('styleSelectionTitle')}
                            </TitleStyled>
                        </HeroColumn>
                    </Container>
                    <RightSidebar />
                </TitleWrapper>
                <StylesSliderBlock />
                <Style data={data} />
                <DefaultFormBlock
                    titleText={t('designDecorator')}
                    withPhoneMobile={false}
                    hideHero
                    tracking={{
                        conversionType: 'BottomFormMobilePosadkaStyle',
                        eventCategory: 'Bottomform',
                    }}
                />
            </Wrap>

            <Desktop id="blockF" onScroll={onScroll}>
                <Header />
                <WrapDesktop>
                    <TitleWrapper>
                        <LeftSidebar background={'#fff'} />
                        <Container columns={'1fr'}>
                            <HeroColumn>
                                <TitleStyled>
                                    {t('styleSelectionTitle')}
                                </TitleStyled>
                            </HeroColumn>
                        </Container>
                        <RightSidebar />
                    </TitleWrapper>
                    <StylesSliderBlock />
                    <StyleDesktop data={data} />
                    <DefaultFormBlock
                        heroText={t('designQuestion')}
                        withPhoneMobile={false}
                        tracking={{
                            conversionType: 'BottomFormPosadkaStyle',
                            eventCategory: 'Bottomform',
                        }}
                    />
                </WrapDesktop>
            </Desktop>
        </>
    )
}

export default StylePage

export const query = graphql`
    query {
        allStyleYaml {
            edges {
                node {
                    title
                    itemsTitle
                    items {
                        answer
                    }
                    itemsListTitle
                    itemsList {
                        question
                        answer
                    }
                    itemsNew {
                        question
                        answer
                    }
                    itemsSlider {
                        question
                        answer
                        svg
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
