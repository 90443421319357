import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { backgroundColors, colors } from 'styles/colors'
import { IconListQuestion } from 'components/IconListQuestion'
import { displayWidth } from 'styles/width'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { Title } from 'components/TitleComponent'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { LeftSidebar, RightSidebar } from 'components/SideBar'

import { indent } from 'styles/indent'

const ProjectStructureWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: ${backgroundColors.contact};
    position: relative;
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: 1px solid ${colors.dark};
    }
    ${mobileAfterBorder}
`
const IconListStyled = styled(IconListQuestion)`
    background-color: ${backgroundColors.contact};
    border-bottom: 1px solid ${colors.dark};
    @media (min-width: ${displayWidth.tablet}) {
        border-bottom: none;
    }
`

const IconListStyledTop = styled(IconListStyled)`
    @media (min-width: ${displayWidth.tablet}) {
        padding: 20px 48px;
    }
`

const TitleStyledH2 = styled(Title)`
    max-width: 250px;
    @media (min-width: ${displayWidth.tablet}) {
        margin-left: ${indent.heroColumnTablet};
        max-width: 90%;
        width: 100%;
        text-align: center;
    }
    @media (min-width: ${displayWidth.desktop}) {
        margin-left: ${indent.heroColumnDesktop};
        max-width: 1000px;
        width: 100%;
        text-align: center;
    }
`

export const Style = ({
    id,
    data,
}: {
    id?: string
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data?: any
}) => {
    const { i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allStyleYaml,
        i18n.language
    )
    const {
        items,
        itemsNew,
        title,
        itemsSlider,
        itemsListTitle,
        itemsList,
        itemsTitle,
    } = projectStructureData

    return (
        <ProjectStructureWrapper id={id}>
            <LeftSidebar background={backgroundColors.contact} />
            <Container columns={'1fr'}>
                <IconListStyledTop
                    titleSize={24}
                    items={itemsSlider}
                    fill={'transparent'}
                />
                <Title>{itemsListTitle}</Title>
                <IconListStyled
                    titleSize={30}
                    items={itemsList}
                    fill={colors.blue}
                />
                <Title>{itemsTitle}</Title>
                <IconListStyled
                    titleSize={30}
                    items={items}
                    fill={colors.blue}
                />
                <Title>{title}</Title>
                <IconListStyled items={itemsNew} fill={colors.blue} />
            </Container>
            <RightSidebar />
        </ProjectStructureWrapper>
    )
}

export const StyleDesktop = ({
    id,
    data,
}: {
    id?: string
    /* eslint-disable @typescript-eslint/no-explicit-any */
    data?: any
}) => {
    const { i18n } = useTranslation()

    const projectStructureData = getDataByLanguage(
        data.allStyleYaml,
        i18n.language
    )
    const {
        items,
        itemsNew,
        title,
        itemsSlider,
        itemsListTitle,
        itemsList,
        itemsTitle,
    } = projectStructureData

    return (
        <ProjectStructureWrapper id={id}>
            <LeftSidebar background={backgroundColors.contact} />
            <Container columns={'1fr'}>
                <IconListStyledTop
                    titleSize={24}
                    items={itemsSlider}
                    fill={'transparent'}
                />
                <TitleStyledH2>{itemsListTitle}</TitleStyledH2>
                <IconListStyled
                    titleSize={30}
                    items={itemsList}
                    fill={colors.blue}
                />
                <TitleStyledH2>{itemsTitle}</TitleStyledH2>
                <IconListStyled
                    titleSize={30}
                    items={items}
                    fill={colors.blue}
                />
                <TitleStyledH2>{title}</TitleStyledH2>
                <IconListStyled items={itemsNew} fill={colors.blue} />
            </Container>
            <RightSidebar />
        </ProjectStructureWrapper>
    )
}
