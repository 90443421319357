import React from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { colors } from 'styles/colors'

import { displayWidth } from 'styles/width'
import { IItem } from 'types/interfaces'

const IconListWrapper = styled.div<{ background: string; number?: boolean }>`
    background-color: ${(props) =>
        props.background ? props.background : '#f7dfd7'};
    display: flex;
    flex-wrap: wrap;
    padding: ${(props) => (props.number ? '0 16px 0 0' : '0 16px')};
    box-sizing: border-box;
    z-index: 1;
    @media (min-width: ${displayWidth.tablet}) {
        padding: 46px 10px 46px 30px;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 0px 48px 20px;
    }
`
const IconItem = styled.div<{ number?: boolean }>`
    /* flex-direction: column; */
    width: 100%;
    padding: ${(props) => (props.number ? '10 0' : '20px 16px')};
    box-sizing: border-box;
    align-items: center;
    @media (min-width: ${displayWidth.tablet}) {
        align-items: flex-start;
    }
    @media (min-width: ${displayWidth.desktop}) {
        padding: 10px 16px;
    }
    > div {
        display: flex;
        align-items: start;
    }
`
const Text = styled.span<{ fill: string }>`
    text-align: left;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.4px;
    li {
        margin: 20px;
        list-style: none;
        display: flex;
    }
    .circle {
        font-size: 50px;
        color: ${(props) => (props.fill ? props.fill : '#5f7db5')};
        height: 100%;
        width: 15px;
        margin-right: 7px;
    }
    .number {
        font-size: 20px;
        font-weight: bold;
        width: 15px;
        margin-right: 10px;
    }
    p,
    h2 {
        display: inline-block;
        color: #000;
        font-size: 20px;
        width: 90%;
    }
    a {
        color: red;
    }
    @media (min-width: ${displayWidth.tablet}) {
        text-align: left;
    }
`
const Text2 = styled.span<{ size: number; fill: string }>`
    border-left: 8px solid ${(props) => (props.fill ? props.fill : '#5f7db5')};
    margin-bottom: 15px;
    p,
    h2 {
        display: flex;
        align-items: center;
        font-size: ${(props) => (props.size ? `${props.size}px` : '24px')};
        line-height: 26px;
        margin-left: 15px;
        min-height: 46px;
        text-align: left;
        box-sizing: border-box;
    }
`

const Text3 = styled.span<{ size: number; fill: string }>`
    border-left: ${(props) =>
        `8px solid (${props.fill} ? ${props.fill}  : '#5f7db5')`};
    margin-bottom: 15px;
    margin-left: 5px;
    p {
        font-size: ${(props) =>
            props.size ? `${props.size}px` : '24px'} !important;
    }
    ul {
        list-style: disc;
        li::marker {
            color: ${colors.accentText};
        }
    }
    li {
        font-size: ${(props) => (props.size ? `${props.size - 8}px` : '16px')};
    }
`
const Number = styled.div<{ size?: number; fill?: string }>`
    width: 30px;
    flex-shrink: 0;
    align-items: center;
    padding: 0 4px;
    margin-right: 10px;
    text-align: right;
    font-size: ${(props) => (props.size ? `${props.size}px` : '24px')};
    color: ${(props) => (props.fill ? props.fill : '#5f7db5')};
    font-weight: 700;
`
interface IIconListProps {
    items?: IItem[]
    fill?: string
    titleSize?: number
    background?: string
    number?: boolean
}

export const IconListQuestion: React.FC<IIconListProps> = ({
    items = [],
    background = colors.white,
    titleSize,
    fill,
    ...restProps
}) => {
    return (
        <IconListWrapper background={background} {...restProps}>
            {items.map((item: IItem) => {
                return (
                    <IconItem key={uuid()}>
                        {item.question && (
                            <div>
                                <Text2
                                    fill={fill!}
                                    size={titleSize!}
                                    dangerouslySetInnerHTML={{
                                        __html: item.question,
                                    }}
                                />
                            </div>
                        )}
                        <Text
                            dangerouslySetInnerHTML={{
                                __html: item.answer,
                            }}
                            fill={fill!}
                        />
                    </IconItem>
                )
            })}
        </IconListWrapper>
    )
}

export const IconListNumber: React.FC<IIconListProps> = ({
    items = [],
    background = colors.white,
    titleSize = 28,
    fill = colors.accentText,
    ...restProps
}) => {
    return (
        <IconListWrapper number background={background} {...restProps}>
            {items.map((item: IItem, i: number) => {
                return (
                    <IconItem number key={uuid()}>
                        {item.question && (
                            <div>
                                <Number fill={fill} size={titleSize}>{`${
                                    i + 1
                                }`}</Number>

                                <Text3
                                    fill={fill}
                                    size={titleSize}
                                    dangerouslySetInnerHTML={{
                                        __html: item.question,
                                    }}
                                />
                            </div>
                        )}
                    </IconItem>
                )
            })}
        </IconListWrapper>
    )
}
