import styled from 'styled-components'
import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'

export const LeftSidebar = styled.div<{ background?: string }>`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: flex;
        flex-grow: 1;
        min-width: 79px;
        background-color: ${(props) =>
            props.background || backgroundColors.contact};
        box-sizing: border-box;
        margin-left: 1px;
    }
`

export const RightSidebar = styled.div<{ background?: string }>`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: flex;
        flex-grow: 1;
        min-width: 79px;
        background-color: ${(props) => props.background || colors.white};
        box-sizing: border-box;
        margin-left: 1px;
    }
`
